/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CoopDonor } from '../models/coop-donor';
import { CreateCoopSourceResult } from '../models/create-coop-source-result';
import { UpdateCoopSourceResult } from '../models/update-coop-source-result';

@Injectable({
  providedIn: 'root',
})
export class CoopDonorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCoopDonor
   */
  static readonly GetCoopDonorPath = '/donors/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopDonor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonor$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<CoopDonor>> {

    const rb = new RequestBuilder(this.rootUrl, CoopDonorControllerService.GetCoopDonorPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoopDonor>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCoopDonor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonor(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<CoopDonor> {

    return this.getCoopDonor$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CoopDonor>) => r.body as CoopDonor)
    );
  }

  /**
   * Path part for operation update1
   */
  static readonly Update1Path = '/donors/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1$Response(params: {
    guid: string;
    context?: HttpContext
    body: CoopDonor
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<UpdateCoopSourceResult>> {

    const rb = new RequestBuilder(this.rootUrl, CoopDonorControllerService.Update1Path, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCoopSourceResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1(params: {
    guid: string;
    context?: HttpContext
    body: CoopDonor
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<UpdateCoopSourceResult> {

    return this.update1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<UpdateCoopSourceResult>) => r.body as UpdateCoopSourceResult)
    );
  }

  /**
   * Path part for operation getCoopDonorsByPatientGuid
   */
  static readonly GetCoopDonorsByPatientGuidPath = '/donors/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopDonorsByPatientGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonorsByPatientGuid$Response(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CoopDonor>>> {

    const rb = new RequestBuilder(this.rootUrl, CoopDonorControllerService.GetCoopDonorsByPatientGuidPath, 'get');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CoopDonor>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCoopDonorsByPatientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonorsByPatientGuid(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CoopDonor>> {

    return this.getCoopDonorsByPatientGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CoopDonor>>) => r.body as Array<CoopDonor>)
    );
  }

  /**
   * Path part for operation create1
   */
  static readonly Create1Path = '/donors/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: {
    context?: HttpContext
    body: CoopDonor
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<CreateCoopSourceResult>> {

    const rb = new RequestBuilder(this.rootUrl, CoopDonorControllerService.Create1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCoopSourceResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: {
    context?: HttpContext
    body: CoopDonor
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<CreateCoopSourceResult> {

    return this.create1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CreateCoopSourceResult>) => r.body as CreateCoopSourceResult)
    );
  }

  /**
   * Path part for operation publishAddMatchedDonor
   */
  static readonly PublishAddMatchedDonorPath = '/donors/coop/publish/matched/{donorGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishAddMatchedDonor()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedDonor$Response(params: {
    donorGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoopDonorControllerService.PublishAddMatchedDonorPath, 'get');
    if (params) {
      rb.path('donorGuid', params.donorGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publishAddMatchedDonor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedDonor(params: {
    donorGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.publishAddMatchedDonor$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
