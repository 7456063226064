/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BusinessPartyDefaults } from '../models/business-party-defaults';
import { SubjectInquiryAnswers } from '../models/subject-inquiry-answers';
import { Workup } from '../models/workup';
import { WorkupId } from '../models/workup-id';
import { WorkupInProgressInfo } from '../models/workup-in-progress-info';
import { WuDefaultWorkup } from '../models/wu-default-workup';

@Injectable({
  providedIn: 'root',
})
export class WorkupEnhancedControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateWorkingWorkup
   */
  static readonly UpdateWorkingWorkupPath = '/workup/enhanced/working-workup/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkingWorkup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkingWorkup$Response(params: {
    orderGuid: string;
    isOriginalForm?: boolean;
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.UpdateWorkingWorkupPath, 'put');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.query('isOriginalForm', params.isOriginalForm, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWorkingWorkup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkingWorkup(params: {
    orderGuid: string;
    isOriginalForm?: boolean;
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateWorkingWorkup$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadWorkupInProgress
   */
  static readonly LoadWorkupInProgressPath = '/workup/enhanced/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgress$Response(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<WorkupInProgressInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.LoadWorkupInProgressPath, 'get');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkupInProgressInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgress(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<WorkupInProgressInfo>> {

    return this.loadWorkupInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<WorkupInProgressInfo>>) => r.body as Array<WorkupInProgressInfo>)
    );
  }

  /**
   * Path part for operation updateWorkupInProgress
   */
  static readonly UpdateWorkupInProgressPath = '/workup/enhanced/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkupInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupInProgress$Response(params: {
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.UpdateWorkupInProgressPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWorkupInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupInProgress(params: {
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateWorkupInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveWorkupInProgress
   */
  static readonly SaveWorkupInProgressPath = '/workup/enhanced/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkupInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupInProgress$Response(params: {
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<WorkupInProgressInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.SaveWorkupInProgressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkupInProgressInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveWorkupInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupInProgress(params: {
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<WorkupInProgressInfo> {

    return this.saveWorkupInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupInProgressInfo>) => r.body as WorkupInProgressInfo)
    );
  }

  /**
   * Path part for operation getWorkupByOrderGuid
   */
  static readonly GetWorkupByOrderGuidPath = '/workup/enhanced/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkupByOrderGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupByOrderGuid$Response(params: {
    orderGuid: string;
    isOriginalForm: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Workup>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.GetWorkupByOrderGuidPath, 'get');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.query('isOriginalForm', params.isOriginalForm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Workup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorkupByOrderGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupByOrderGuid(params: {
    orderGuid: string;
    isOriginalForm: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Workup> {

    return this.getWorkupByOrderGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Workup>) => r.body as Workup)
    );
  }

  /**
   * Path part for operation saveWorkup
   */
  static readonly SaveWorkupPath = '/workup/enhanced/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkup$Response(params: {
    orderGuid: string;
    isOriginalForm?: boolean;
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<WorkupId>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.SaveWorkupPath, 'post');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.query('isOriginalForm', params.isOriginalForm, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkupId>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveWorkup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkup(params: {
    orderGuid: string;
    isOriginalForm?: boolean;
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<WorkupId> {

    return this.saveWorkup$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupId>) => r.body as WorkupId)
    );
  }

  /**
   * Path part for operation extractSubjectInquiryAnswers
   */
  static readonly ExtractSubjectInquiryAnswersPath = '/workup/enhanced/workup-answers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extractSubjectInquiryAnswers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  extractSubjectInquiryAnswers$Response(params: {
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SubjectInquiryAnswers>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.ExtractSubjectInquiryAnswersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubjectInquiryAnswers>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `extractSubjectInquiryAnswers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  extractSubjectInquiryAnswers(params: {
    context?: HttpContext
    body: Workup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SubjectInquiryAnswers> {

    return this.extractSubjectInquiryAnswers$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SubjectInquiryAnswers>) => r.body as SubjectInquiryAnswers)
    );
  }

  /**
   * Path part for operation savePatientDefaults
   */
  static readonly SavePatientDefaultsPath = '/workup/enhanced/patient-default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePatientDefaults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePatientDefaults$Response(params: {
    context?: HttpContext
    body: WuDefaultWorkup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.SavePatientDefaultsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePatientDefaults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePatientDefaults(params: {
    context?: HttpContext
    body: WuDefaultWorkup
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.savePatientDefaults$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveBusinessPartyDefaults
   */
  static readonly SaveBusinessPartyDefaultsPath = '/workup/enhanced/bp-default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveBusinessPartyDefaults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveBusinessPartyDefaults$Response(params: {
    context?: HttpContext
    body: BusinessPartyDefaults
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.SaveBusinessPartyDefaultsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveBusinessPartyDefaults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveBusinessPartyDefaults(params: {
    context?: HttpContext
    body: BusinessPartyDefaults
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.saveBusinessPartyDefaults$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation publishCreateRecipientPrescription1
   */
  static readonly PublishCreateRecipientPrescription1Path = '/workup/enhanced/publish/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishCreateRecipientPrescription1()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription1$Response(params: {
    orderGuid: string;
    serviceCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.PublishCreateRecipientPrescription1Path, 'get');
    if (params) {
      rb.query('orderGuid', params.orderGuid, {});
      rb.query('serviceCode', params.serviceCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publishCreateRecipientPrescription1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription1(params: {
    orderGuid: string;
    serviceCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.publishCreateRecipientPrescription1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadPatientDefaults
   */
  static readonly LoadPatientDefaultsPath = '/workup/enhanced/patient-default/{bpGuid}/{subjectGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPatientDefaults()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPatientDefaults$Response(params: {
    bpGuid: string;
    subjectGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<WuDefaultWorkup>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.LoadPatientDefaultsPath, 'get');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
      rb.path('subjectGuid', params.subjectGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WuDefaultWorkup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadPatientDefaults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPatientDefaults(params: {
    bpGuid: string;
    subjectGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<WuDefaultWorkup> {

    return this.loadPatientDefaults$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WuDefaultWorkup>) => r.body as WuDefaultWorkup)
    );
  }

  /**
   * Path part for operation loadWorkupInProgressById
   */
  static readonly LoadWorkupInProgressByIdPath = '/workup/enhanced/in-progress/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupInProgressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgressById$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Workup>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.LoadWorkupInProgressByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Workup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupInProgressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgressById(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Workup> {

    return this.loadWorkupInProgressById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Workup>) => r.body as Workup)
    );
  }

  /**
   * Path part for operation removeWorkupInProgress
   */
  static readonly RemoveWorkupInProgressPath = '/workup/enhanced/in-progress/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeWorkupInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupInProgress$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.RemoveWorkupInProgressPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeWorkupInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupInProgress(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.removeWorkupInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLatestInProgressVerificationByGuid
   */
  static readonly GetLatestInProgressVerificationByGuidPath = '/workup/enhanced/in-progress/latest/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestInProgressVerificationByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestInProgressVerificationByGuid$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.GetLatestInProgressVerificationByGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestInProgressVerificationByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestInProgressVerificationByGuid(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<boolean> {

    return this.getLatestInProgressVerificationByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation loadBusinessPartyDefaults
   */
  static readonly LoadBusinessPartyDefaultsPath = '/workup/enhanced/bp-default/{bpGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadBusinessPartyDefaults()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadBusinessPartyDefaults$Response(params: {
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<WuDefaultWorkup>> {

    const rb = new RequestBuilder(this.rootUrl, WorkupEnhancedControllerService.LoadBusinessPartyDefaultsPath, 'get');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WuDefaultWorkup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadBusinessPartyDefaults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadBusinessPartyDefaults(params: {
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<WuDefaultWorkup> {

    return this.loadBusinessPartyDefaults$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WuDefaultWorkup>) => r.body as WuDefaultWorkup)
    );
  }

}
