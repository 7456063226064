/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {FormDue} from '../models/form-due';
import {FormDueDonorIdSearchRequest} from '../models/form-due-donor-id-search-request';
import {FormDueExistResponse} from '../models/form-due-exist-response';
import {PageFormDue} from '../models/page-form-due';
import {RequestResolutionRequest} from '../models/request-resolution-request';

@Injectable({
  providedIn: 'root',
})
export class FormDueControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation loadFormDue
   */
  static readonly LoadFormDuePath = '/form-due';

  /**
   * This method loads all Forms 22 Due for provided criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadFormDue()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadFormDue$Response(
    params: {
      bpGuid: string;
      secondaryCode?: string;
      pastDue?: boolean;
      startDte?: string;
      endDte?: string;
      closedDte?: string;
      skipPagination?: boolean;
      manuallyClosed?: boolean;
      productType?: 'NMDP' | 'COOP' | 'BMDW' | 'BMDWD' | 'MBR' | 'UNKNOWN';
      recipientGuid?: string;

      /**
       * Zero-based page index (0..N)
       */
      page?: number;

      /**
       * The size of the page to be returned
       */
      size?: number;

      /**
       * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       */
      sort?: Array<string>;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<PageFormDue>> {
    const rb = new RequestBuilder(this.rootUrl, FormDueControllerService.LoadFormDuePath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('secondaryCode', params.secondaryCode, {});
      rb.query('pastDue', params.pastDue, {});
      rb.query('startDte', params.startDte, {});
      rb.query('endDte', params.endDte, {});
      rb.query('closedDte', params.closedDte, {});
      rb.query('skipPagination', params.skipPagination, {});
      rb.query('manuallyClosed', params.manuallyClosed, {});
      rb.query('productType', params.productType, {});
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PageFormDue>;
        })
      );
  }

  /**
   * This method loads all Forms 22 Due for provided criteria.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadFormDue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadFormDue(
    params: {
      bpGuid: string;
      secondaryCode?: string;
      pastDue?: boolean;
      startDte?: string;
      endDte?: string;
      closedDte?: string;
      skipPagination?: boolean;
      manuallyClosed?: boolean;
      productType?: 'NMDP' | 'COOP' | 'BMDW' | 'BMDWD' | 'MBR' | 'UNKNOWN';
      recipientGuid?: string;

      /**
       * Zero-based page index (0..N)
       */
      page?: number;

      /**
       * The size of the page to be returned
       */
      size?: number;

      /**
       * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       */
      sort?: Array<string>;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<PageFormDue> {
    return this.loadFormDue$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageFormDue>) => r.body as PageFormDue)
    );
  }

  /**
   * Path part for operation updateFormDue
   */
  static readonly UpdateFormDuePath = '/form-due';

  /**
   * This method updates Form 22 Due.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFormDue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormDue$Response(
    params: {
      context?: HttpContext;
      body: FormDue;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormDueControllerService.UpdateFormDuePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method updates Form 22 Due.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFormDue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormDue(
    params: {
      context?: HttpContext;
      body: FormDue;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateFormDue$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createFormDue
   */
  static readonly CreateFormDuePath = '/form-due';

  /**
   * This method creates Form 22 Due.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFormDue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormDue$Response(
    params: {
      context?: HttpContext;
      body: FormDue;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormDueControllerService.CreateFormDuePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method creates Form 22 Due.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFormDue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormDue(
    params: {
      context?: HttpContext;
      body: FormDue;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.createFormDue$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadFormsDueByDonorId
   */
  static readonly LoadFormsDueByDonorIdPath = '/form-due/search';

  /**
   * This method loads forms filtered by given donor's ID (e.g. GRID)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadFormsDueByDonorId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadFormsDueByDonorId$Response(
    params: {
      context?: HttpContext;
      body: FormDueDonorIdSearchRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<FormDue>>> {
    const rb = new RequestBuilder(this.rootUrl, FormDueControllerService.LoadFormsDueByDonorIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FormDue>>;
        })
      );
  }

  /**
   * This method loads forms filtered by given donor's ID (e.g. GRID)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadFormsDueByDonorId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadFormsDueByDonorId(
    params: {
      context?: HttpContext;
      body: FormDueDonorIdSearchRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<FormDue>> {
    return this.loadFormsDueByDonorId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<FormDue>>) => r.body as Array<FormDue>)
    );
  }

  /**
   * Path part for operation requestResolution
   */
  static readonly RequestResolutionPath = '/form-due/resolution/request';

  /**
   * Request a resolution for a row in the Form 22 Due
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestResolution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestResolution$Response(
    params?: {
      files?: Array<Blob>;
      context?: HttpContext;
      body?: {
        request: RequestResolutionRequest;
      };
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormDueControllerService.RequestResolutionPath, 'post');
    if (params) {
      rb.query('files', params.files, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Request a resolution for a row in the Form 22 Due
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestResolution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestResolution(
    params?: {
      files?: Array<Blob>;
      context?: HttpContext;
      body?: {
        request: RequestResolutionRequest;
      };
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.requestResolution$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation checkIfFormDueExists
   */
  static readonly CheckIfFormDueExistsPath = '/form-due/exist';

  /**
   * Checks if open Form Due records exists for provided criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfFormDueExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfFormDueExists$Response(
    params: {
      bpGuid: string;
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<FormDueExistResponse>> {
    const rb = new RequestBuilder(this.rootUrl, FormDueControllerService.CheckIfFormDueExistsPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('recipientGuid', params.recipientGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FormDueExistResponse>;
        })
      );
  }

  /**
   * Checks if open Form Due records exists for provided criteria.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkIfFormDueExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfFormDueExists(
    params: {
      bpGuid: string;
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<FormDueExistResponse> {
    return this.checkIfFormDueExists$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<FormDueExistResponse>) => r.body as FormDueExistResponse)
    );
  }
}
