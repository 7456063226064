/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DuplicatePatient } from '../models/duplicate-patient';
import { DuplicatePatientHistory } from '../models/duplicate-patient-history';
import { DuplicatePatientInfo } from '../models/duplicate-patient-info';
import { DuplicatePatientResponse } from '../models/duplicate-patient-response';
import { ManualResolveRequest } from '../models/manual-resolve-request';
import { RecipientDuplicate } from '../models/recipient-duplicate';

@Injectable({
  providedIn: 'root',
})
export class DuplicatePatientControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation resolveDuplicates
   */
  static readonly ResolveDuplicatesPath = '/duplicate/patients/resolve/{guid}';

  /**
   * Resolve whether recipient is a duplicate or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveDuplicates()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveDuplicates$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DuplicatePatientControllerService.ResolveDuplicatesPath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Resolve whether recipient is a duplicate or not
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resolveDuplicates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveDuplicates(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.resolveDuplicates$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation resolveDuplicatesManually
   */
  static readonly ResolveDuplicatesManuallyPath = '/duplicate/patients/manual/resolve';

  /**
   * Manually resolve whether recipient is a duplicate or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveDuplicatesManually()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resolveDuplicatesManually$Response(params: {
    context?: HttpContext
    body: ManualResolveRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DuplicatePatientControllerService.ResolveDuplicatesManuallyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Manually resolve whether recipient is a duplicate or not
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resolveDuplicatesManually$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resolveDuplicatesManually(params: {
    context?: HttpContext
    body: ManualResolveRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.resolveDuplicatesManually$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation checkForDuplicates
   */
  static readonly CheckForDuplicatesPath = '/duplicate/patients/check';

  /**
   * Check is patient a duplicate or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkForDuplicates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkForDuplicates$Response(params: {
    context?: HttpContext
    body: DuplicatePatient
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<RecipientDuplicate>> {

    const rb = new RequestBuilder(this.rootUrl, DuplicatePatientControllerService.CheckForDuplicatesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipientDuplicate>;
      })
    );
  }

  /**
   * Check is patient a duplicate or not
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkForDuplicates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkForDuplicates(params: {
    context?: HttpContext
    body: DuplicatePatient
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<RecipientDuplicate> {

    return this.checkForDuplicates$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<RecipientDuplicate>) => r.body as RecipientDuplicate)
    );
  }

  /**
   * Path part for operation getAllUnresolved
   */
  static readonly GetAllUnresolvedPath = '/duplicate/patients';

  /**
   * Get all unresolved duplicates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUnresolved()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUnresolved$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DuplicatePatientResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DuplicatePatientControllerService.GetAllUnresolvedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DuplicatePatientResponse>>;
      })
    );
  }

  /**
   * Get all unresolved duplicates
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUnresolved$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUnresolved(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DuplicatePatientResponse>> {

    return this.getAllUnresolved$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DuplicatePatientResponse>>) => r.body as Array<DuplicatePatientResponse>)
    );
  }

  /**
   * Path part for operation getDuplicateInfo
   */
  static readonly GetDuplicateInfoPath = '/duplicate/patients/info/{guid}';

  /**
   * Get duplicate patient info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDuplicateInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateInfo$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<DuplicatePatientInfo>> {

    const rb = new RequestBuilder(this.rootUrl, DuplicatePatientControllerService.GetDuplicateInfoPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicatePatientInfo>;
      })
    );
  }

  /**
   * Get duplicate patient info
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDuplicateInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateInfo(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<DuplicatePatientInfo> {

    return this.getDuplicateInfo$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DuplicatePatientInfo>) => r.body as DuplicatePatientInfo)
    );
  }

  /**
   * Path part for operation getDuplicateHistory
   */
  static readonly GetDuplicateHistoryPath = '/duplicate/patients/history/{guid}';

  /**
   * Get duplicate patient history
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDuplicateHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateHistory$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DuplicatePatientHistory>>> {

    const rb = new RequestBuilder(this.rootUrl, DuplicatePatientControllerService.GetDuplicateHistoryPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DuplicatePatientHistory>>;
      })
    );
  }

  /**
   * Get duplicate patient history
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDuplicateHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateHistory(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DuplicatePatientHistory>> {

    return this.getDuplicateHistory$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DuplicatePatientHistory>>) => r.body as Array<DuplicatePatientHistory>)
    );
  }

}
