/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {Allele} from '../models/allele';
import {BloodType} from '../models/blood-type';
import {CcrType} from '../models/ccr-type';
import {ClinicalRationale} from '../models/clinical-rationale';
import {CmvStatus} from '../models/cmv-status';
import {Disease} from '../models/disease';
import {DiseaseStage} from '../models/disease-stage';
import {Dsa} from '../models/dsa';
import {EthnicityDto} from '../models/ethnicity-dto';
import {Genders} from '../models/genders';
import {Language} from '../models/language';
import {MatchPreference} from '../models/match-preference';
import {NotReferredReason} from '../models/not-referred-reason';
import {PreferredProduct} from '../models/preferred-product';
import {Race} from '../models/race';
import {RhType} from '../models/rh-type';
import {Sex} from '../models/sex';
import {Status} from '../models/status';
import {StatusReason} from '../models/status-reason';
import {SubsequentRequestReason} from '../models/subsequent-request-reason';
import {TransplantTimeline} from '../models/transplant-timeline';

@Injectable({
  providedIn: 'root',
})
export class NomenclaturesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation loadTransplantTimelines
   */
  static readonly LoadTransplantTimelinesPath = '/nomenclatures/transplant-timelines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadTransplantTimelines()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadTransplantTimelines$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<TransplantTimeline>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadTransplantTimelinesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TransplantTimeline>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadTransplantTimelines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadTransplantTimelines(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<TransplantTimeline>> {
    return this.loadTransplantTimelines$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<TransplantTimeline>>) => r.body as Array<TransplantTimeline>)
    );
  }

  /**
   * Path part for operation getSubsequentRequestReasons
   */
  static readonly GetSubsequentRequestReasonsPath = '/nomenclatures/subsequent-requests-reasons';

  /**
   * Provides a list of subsequent request reason
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubsequentRequestReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsequentRequestReasons$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<SubsequentRequestReason>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.GetSubsequentRequestReasonsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<SubsequentRequestReason>>;
        })
      );
  }

  /**
   * Provides a list of subsequent request reason
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubsequentRequestReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsequentRequestReasons(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<SubsequentRequestReason>> {
    return this.getSubsequentRequestReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<SubsequentRequestReason>>) => r.body as Array<SubsequentRequestReason>)
    );
  }

  /**
   * Path part for operation listAllStatuses
   */
  static readonly ListAllStatusesPath = '/nomenclatures/statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatuses$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Status>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllStatusesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Status>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatuses(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Status>> {
    return this.listAllStatuses$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Status>>) => r.body as Array<Status>)
    );
  }

  /**
   * Path part for operation listAllStatusReasons
   */
  static readonly ListAllStatusReasonsPath = '/nomenclatures/status/reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllStatusReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatusReasons$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<StatusReason>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllStatusReasonsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StatusReason>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllStatusReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatusReasons(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<StatusReason>> {
    return this.listAllStatusReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<StatusReason>>) => r.body as Array<StatusReason>)
    );
  }

  /**
   * Path part for operation getShortAlleles
   */
  static readonly GetShortAllelesPath = '/nomenclatures/short-alleles/{longLocusName}/{groName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShortAlleles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShortAlleles$Response(
    params: {
      longLocusName: string;
      groName: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<
    StrictHttpResponse<{
      [key: string]: {};
    }>
  > {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.GetShortAllelesPath, 'get');
    if (params) {
      rb.path('longLocusName', params.longLocusName, {});
      rb.path('groName', params.groName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            [key: string]: {};
          }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShortAlleles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShortAlleles(
    params: {
      longLocusName: string;
      groName: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{
    [key: string]: {};
  }> {
    return this.getShortAlleles$Response(params, requestOptions).pipe(
      map(
        (
          r: StrictHttpResponse<{
            [key: string]: {};
          }>
        ) =>
          r.body as {
            [key: string]: {};
          }
      )
    );
  }

  /**
   * Path part for operation loadSexes
   */
  static readonly LoadSexesPath = '/nomenclatures/sexes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadSexes()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadSexes$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Sex>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadSexesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Sex>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadSexes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadSexes(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Sex>> {
    return this.loadSexes$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Sex>>) => r.body as Array<Sex>)
    );
  }

  /**
   * Path part for operation loadRhs
   */
  static readonly LoadRhsPath = '/nomenclatures/rhs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadRhs()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadRhs$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<RhType>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadRhsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RhType>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadRhs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadRhs(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<RhType>> {
    return this.loadRhs$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<RhType>>) => r.body as Array<RhType>)
    );
  }

  /**
   * Path part for operation listRaces
   */
  static readonly ListRacesPath = '/nomenclatures/races';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRaces$Response(
    params?: {
      type?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Race>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListRacesPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Race>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listRaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRaces(
    params?: {
      type?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Race>> {
    return this.listRaces$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Race>>) => r.body as Array<Race>)
    );
  }

  /**
   * Path part for operation loadPreferredProducts
   */
  static readonly LoadPreferredProductsPath = '/nomenclatures/preferred-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPreferredProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPreferredProducts$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<PreferredProduct>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadPreferredProductsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PreferredProduct>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadPreferredProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPreferredProducts(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<PreferredProduct>> {
    return this.loadPreferredProducts$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<PreferredProduct>>) => r.body as Array<PreferredProduct>)
    );
  }

  /**
   * Path part for operation listAllNotReferredReasons
   */
  static readonly ListAllNotReferredReasonsPath = '/nomenclatures/not-referred-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllNotReferredReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllNotReferredReasons$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<NotReferredReason>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllNotReferredReasonsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotReferredReason>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllNotReferredReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllNotReferredReasons(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<NotReferredReason>> {
    return this.listAllNotReferredReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<NotReferredReason>>) => r.body as Array<NotReferredReason>)
    );
  }

  /**
   * Path part for operation listAllMatchPrefernces
   */
  static readonly ListAllMatchPreferncesPath = '/nomenclatures/match-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllMatchPrefernces()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMatchPrefernces$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<MatchPreference>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllMatchPreferncesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MatchPreference>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllMatchPrefernces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMatchPrefernces(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<MatchPreference>> {
    return this.listAllMatchPrefernces$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<MatchPreference>>) => r.body as Array<MatchPreference>)
    );
  }

  /**
   * Path part for operation loadLoci
   */
  static readonly LoadLociPath = '/nomenclatures/loci';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadLoci()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadLoci$Response(
    params?: {
      geneFamily?: 'HLA' | 'KIR';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadLociPath, 'get');
    if (params) {
      rb.query('geneFamily', params.geneFamily, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<string>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadLoci$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadLoci(
    params?: {
      geneFamily?: 'HLA' | 'KIR';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<string>> {
    return this.loadLoci$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getLightAlleles
   */
  static readonly GetLightAllelesPath = '/nomenclatures/light-alleles/{longLocusName}/{groName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLightAlleles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLightAlleles$Response(
    params: {
      longLocusName: string;
      groName: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Allele>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.GetLightAllelesPath, 'get');
    if (params) {
      rb.path('longLocusName', params.longLocusName, {});
      rb.path('groName', params.groName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Allele>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLightAlleles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLightAlleles(
    params: {
      longLocusName: string;
      groName: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Allele>> {
    return this.getLightAlleles$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Allele>>) => r.body as Array<Allele>)
    );
  }

  /**
   * Path part for operation listAllLanguages
   */
  static readonly ListAllLanguagesPath = '/nomenclatures/languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllLanguages$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Language>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllLanguagesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Language>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllLanguages(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Language>> {
    return this.listAllLanguages$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Language>>) => r.body as Array<Language>)
    );
  }

  /**
   * Path part for operation getGenders
   */
  static readonly GetGendersPath = '/nomenclatures/genders';

  /**
   * Provides a list of gender options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGenders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenders$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Genders>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.GetGendersPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Genders>>;
        })
      );
  }

  /**
   * Provides a list of gender options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGenders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenders(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Genders>> {
    return this.getGenders$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Genders>>) => r.body as Array<Genders>)
    );
  }

  /**
   * Path part for operation listAllEthnicities
   */
  static readonly ListAllEthnicitiesPath = '/nomenclatures/ethnicities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllEthnicities()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllEthnicities$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<EthnicityDto>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllEthnicitiesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EthnicityDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllEthnicities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllEthnicities(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<EthnicityDto>> {
    return this.listAllEthnicities$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<EthnicityDto>>) => r.body as Array<EthnicityDto>)
    );
  }

  /**
   * Path part for operation getAntigenAndAlleles
   */
  static readonly GetAntigenAndAllelesPath = '/nomenclatures/dsa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAntigenAndAlleles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAntigenAndAlleles$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Dsa>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.GetAntigenAndAllelesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Dsa>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAntigenAndAlleles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAntigenAndAlleles(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Dsa> {
    return this.getAntigenAndAlleles$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Dsa>) => r.body as Dsa)
    );
  }

  /**
   * Path part for operation listAllDisease
   */
  static readonly ListAllDiseasePath = '/nomenclatures/diseases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllDisease()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDisease$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<Disease>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllDiseasePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Disease>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllDisease$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDisease(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<Disease>> {
    return this.listAllDisease$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Disease>>) => r.body as Array<Disease>)
    );
  }

  /**
   * Path part for operation listAllDiseaseStages
   */
  static readonly ListAllDiseaseStagesPath = '/nomenclatures/diseases/{diseaseCode}/disease-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllDiseaseStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDiseaseStages$Response(
    params: {
      diseaseCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<DiseaseStage>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllDiseaseStagesPath, 'get');
    if (params) {
      rb.path('diseaseCode', params.diseaseCode, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DiseaseStage>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllDiseaseStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDiseaseStages(
    params: {
      diseaseCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<DiseaseStage>> {
    return this.listAllDiseaseStages$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DiseaseStage>>) => r.body as Array<DiseaseStage>)
    );
  }

  /**
   * Path part for operation loadCoopSubmitResult
   */
  static readonly LoadCoopSubmitResultPath = '/nomenclatures/coop/submit-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadCoopSubmitResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopSubmitResult$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<
    StrictHttpResponse<{
      [key: string]: string;
    }>
  > {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadCoopSubmitResultPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            [key: string]: string;
          }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadCoopSubmitResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopSubmitResult(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{
    [key: string]: string;
  }> {
    return this.loadCoopSubmitResult$Response(params, requestOptions).pipe(
      map(
        (
          r: StrictHttpResponse<{
            [key: string]: string;
          }>
        ) =>
          r.body as {
            [key: string]: string;
          }
      )
    );
  }

  /**
   * Path part for operation listAllCmvStatuses
   */
  static readonly ListAllCmvStatusesPath = '/nomenclatures/cmv-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllCmvStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCmvStatuses$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<CmvStatus>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllCmvStatusesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CmvStatus>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllCmvStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCmvStatuses(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<CmvStatus>> {
    return this.listAllCmvStatuses$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CmvStatus>>) => r.body as Array<CmvStatus>)
    );
  }

  /**
   * Path part for operation listAllCloseReasons
   */
  static readonly ListAllCloseReasonsPath = '/nomenclatures/closereasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllCloseReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCloseReasons$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<StatusReason>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllCloseReasonsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StatusReason>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllCloseReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCloseReasons(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<StatusReason>> {
    return this.listAllCloseReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<StatusReason>>) => r.body as Array<StatusReason>)
    );
  }

  /**
   * Path part for operation listAllClinicalRationales
   */
  static readonly ListAllClinicalRationalesPath = '/nomenclatures/clinical-rationales';

  /**
   * Provides a list of clinical rationales
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllClinicalRationales()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllClinicalRationales$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<ClinicalRationale>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllClinicalRationalesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClinicalRationale>>;
        })
      );
  }

  /**
   * Provides a list of clinical rationales
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllClinicalRationales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllClinicalRationales(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<ClinicalRationale>> {
    return this.listAllClinicalRationales$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ClinicalRationale>>) => r.body as Array<ClinicalRationale>)
    );
  }

  /**
   * Path part for operation loadCcr
   */
  static readonly LoadCcrPath = '/nomenclatures/ccr-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadCcr()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCcr$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<CcrType>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LoadCcrPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CcrType>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadCcr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCcr(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<CcrType>> {
    return this.loadCcr$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CcrType>>) => r.body as Array<CcrType>)
    );
  }

  /**
   * Path part for operation listAllBloodTypes
   */
  static readonly ListAllBloodTypesPath = '/nomenclatures/blood-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllBloodTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllBloodTypes$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<BloodType>>> {
    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.ListAllBloodTypesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<BloodType>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllBloodTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllBloodTypes(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<BloodType>> {
    return this.listAllBloodTypes$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BloodType>>) => r.body as Array<BloodType>)
    );
  }
}
