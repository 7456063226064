/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {BLeader} from '../models/b-leader';
import {DetailedBLeader} from '../models/detailed-b-leader';
import {DetailedDpb1Expression} from '../models/detailed-dpb-1-expression';
import {Hla} from '../models/hla';
import {Ligand} from '../models/ligand';
import {LigandRequest} from '../models/ligand-request';
import {ValidatedTyping} from '../models/validated-typing';

@Injectable({
  providedIn: 'root',
})
export class HlaTypingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation validateHla
   */
  static readonly ValidateHlaPath = '/typings/validity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateHla()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateHla$Response(
    params: {
      context?: HttpContext;
      body: Array<Hla>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<ValidatedTyping>>> {
    const rb = new RequestBuilder(this.rootUrl, HlaTypingControllerService.ValidateHlaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ValidatedTyping>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateHla$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateHla(
    params: {
      context?: HttpContext;
      body: Array<Hla>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<ValidatedTyping>> {
    return this.validateHla$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ValidatedTyping>>) => r.body as Array<ValidatedTyping>)
    );
  }

  /**
   * Path part for operation getPatientLigandType
   */
  static readonly GetPatientLigandTypePath = '/typings/ligand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPatientLigandType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPatientLigandType$Response(
    params: {
      context?: HttpContext;
      body: LigandRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Ligand>> {
    const rb = new RequestBuilder(this.rootUrl, HlaTypingControllerService.GetPatientLigandTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Ligand>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPatientLigandType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPatientLigandType(
    params: {
      context?: HttpContext;
      body: LigandRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Ligand> {
    return this.getPatientLigandType$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Ligand>) => r.body as Ligand)
    );
  }

  /**
   * Path part for operation getBLeaderInfo
   */
  static readonly GetBLeaderInfoPath = '/typings/bleader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBLeaderInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBLeaderInfo$Response(
    params: {
      allele: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<BLeader>> {
    const rb = new RequestBuilder(this.rootUrl, HlaTypingControllerService.GetBLeaderInfoPath, 'get');
    if (params) {
      rb.query('allele', params.allele, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BLeader>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBLeaderInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBLeaderInfo(
    params: {
      allele: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<BLeader> {
    return this.getBLeaderInfo$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<BLeader>) => r.body as BLeader)
    );
  }

  /**
   * Path part for operation getBLeaders
   */
  static readonly GetBLeadersPath = '/typings/bleader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBLeaders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBLeaders$Response(
    params: {
      context?: HttpContext;
      body: Array<string>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<
    StrictHttpResponse<{
      [key: string]: BLeader;
    }>
  > {
    const rb = new RequestBuilder(this.rootUrl, HlaTypingControllerService.GetBLeadersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            [key: string]: BLeader;
          }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBLeaders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBLeaders(
    params: {
      context?: HttpContext;
      body: Array<string>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{
    [key: string]: BLeader;
  }> {
    return this.getBLeaders$Response(params, requestOptions).pipe(
      map(
        (
          r: StrictHttpResponse<{
            [key: string]: BLeader;
          }>
        ) =>
          r.body as {
            [key: string]: BLeader;
          }
      )
    );
  }

  /**
   * Path part for operation getDetailedDpb1Expression
   */
  static readonly GetDetailedDpb1ExpressionPath = '/typings/dpb1-expression/detailed-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedDpb1Expression()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedDpb1Expression$Response(
    params: {
      allele: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<DetailedDpb1Expression>> {
    const rb = new RequestBuilder(this.rootUrl, HlaTypingControllerService.GetDetailedDpb1ExpressionPath, 'get');
    if (params) {
      rb.query('allele', params.allele, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DetailedDpb1Expression>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDetailedDpb1Expression$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedDpb1Expression(
    params: {
      allele: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<DetailedDpb1Expression> {
    return this.getDetailedDpb1Expression$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DetailedDpb1Expression>) => r.body as DetailedDpb1Expression)
    );
  }

  /**
   * Path part for operation getDetailedBLeaderInfo
   */
  static readonly GetDetailedBLeaderInfoPath = '/typings/bleader/detailed-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedBLeaderInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedBLeaderInfo$Response(
    params: {
      allele: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<DetailedBLeader>> {
    const rb = new RequestBuilder(this.rootUrl, HlaTypingControllerService.GetDetailedBLeaderInfoPath, 'get');
    if (params) {
      rb.query('allele', params.allele, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DetailedBLeader>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDetailedBLeaderInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedBLeaderInfo(
    params: {
      allele: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<DetailedBLeader> {
    return this.getDetailedBLeaderInfo$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DetailedBLeader>) => r.body as DetailedBLeader)
    );
  }
}
