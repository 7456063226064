/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentDetails } from '../models/document-details';

@Injectable({
  providedIn: 'root',
})
export class InternationalFormDocumentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reassignWorkupDocuments
   */
  static readonly ReassignWorkupDocumentsPath = '/int-forms-documents/reassign_documents_workup';

  /**
   * This reassigns workup documents to newly created order workup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignWorkupDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignWorkupDocuments$Response(params: {
    workupInProgressId: number;
    orderGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InternationalFormDocumentControllerService.ReassignWorkupDocumentsPath, 'put');
    if (params) {
      rb.query('workupInProgressId', params.workupInProgressId, {});
      rb.query('orderGuid', params.orderGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This reassigns workup documents to newly created order workup
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reassignWorkupDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignWorkupDocuments(params: {
    workupInProgressId: number;
    orderGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.reassignWorkupDocuments$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addDocument
   */
  static readonly AddDocumentPath = '/int-forms-documents';

  /**
   * This method uploads international form document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDocument$Response(params: {
    files: Array<Blob>;
    workupId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InternationalFormDocumentControllerService.AddDocumentPath, 'post');
    if (params) {
      rb.query('files', params.files, {});
      rb.query('workupId', params.workupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method uploads international form document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDocument(params: {
    files: Array<Blob>;
    workupId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.addDocument$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteDocuments
   */
  static readonly DeleteDocumentsPath = '/int-forms-documents';

  /**
   * This method deletes document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocuments$Response(params: {
    documentsIds: Array<number>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InternationalFormDocumentControllerService.DeleteDocumentsPath, 'delete');
    if (params) {
      rb.query('documentsIds', params.documentsIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method deletes document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocuments(params: {
    documentsIds: Array<number>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.deleteDocuments$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getIntFormDetails
   */
  static readonly GetIntFormDetailsPath = '/int-forms-documents/details';

  /**
   * This method returns all documents details for a given international form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntFormDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntFormDetails$Response(params: {
    workupId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DocumentDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, InternationalFormDocumentControllerService.GetIntFormDetailsPath, 'get');
    if (params) {
      rb.query('workupId', params.workupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDetails>>;
      })
    );
  }

  /**
   * This method returns all documents details for a given international form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIntFormDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntFormDetails(params: {
    workupId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DocumentDetails>> {

    return this.getIntFormDetails$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>) => r.body as Array<DocumentDetails>)
    );
  }

}
