/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {DefaultWorkup} from '../models/default-workup';
import {WorkupAnswer} from '../models/workup-answer';
import {WorkupForm} from '../models/workup-form';
import {WorkupInProgressInfo} from '../models/workup-in-progress-info';

@Injectable({
  providedIn: 'root',
})
export class WorkupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation loadWorkupAnswerInProgressById
   */
  static readonly LoadWorkupAnswerInProgressByIdPath = '/workup/in-progress/answers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupAnswerInProgressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgressById$Response(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupAnswer>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.LoadWorkupAnswerInProgressByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupAnswer>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupAnswerInProgressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgressById(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupAnswer> {
    return this.loadWorkupAnswerInProgressById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>) => r.body as WorkupAnswer)
    );
  }

  /**
   * Path part for operation updateWorkupAnswerInProgress
   */
  static readonly UpdateWorkupAnswerInProgressPath = '/workup/in-progress/answers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkupAnswerInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupAnswerInProgress$Response(
    params: {
      id: number;
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.UpdateWorkupAnswerInProgressPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateWorkupAnswerInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupAnswerInProgress(
    params: {
      id: number;
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateWorkupAnswerInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveWorkupAnswerInProgress
   */
  static readonly SaveWorkupAnswerInProgressPath = '/workup/in-progress/answers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkupAnswerInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswerInProgress$Response(
    params: {
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupInProgressInfo>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.SaveWorkupAnswerInProgressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupInProgressInfo>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveWorkupAnswerInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswerInProgress(
    params: {
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupInProgressInfo> {
    return this.saveWorkupAnswerInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupInProgressInfo>) => r.body as WorkupInProgressInfo)
    );
  }

  /**
   * Path part for operation loadDefaultWorkupAnswer
   */
  static readonly LoadDefaultWorkupAnswerPath = '/workup/default/{bpGuid}/{version}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadDefaultWorkupAnswer()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadDefaultWorkupAnswer$Response(
    params: {
      bpGuid: string;
      version: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<DefaultWorkup>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.LoadDefaultWorkupAnswerPath, 'get');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
      rb.path('version', params.version, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultWorkup>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadDefaultWorkupAnswer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadDefaultWorkupAnswer(
    params: {
      bpGuid: string;
      version: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<DefaultWorkup> {
    return this.loadDefaultWorkupAnswer$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DefaultWorkup>) => r.body as DefaultWorkup)
    );
  }

  /**
   * Path part for operation saveDefaultWorkupAnswer
   */
  static readonly SaveDefaultWorkupAnswerPath = '/workup/default/{bpGuid}/{version}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveDefaultWorkupAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDefaultWorkupAnswer$Response(
    params: {
      bpGuid: string;
      version: string;
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.SaveDefaultWorkupAnswerPath, 'post');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
      rb.path('version', params.version, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveDefaultWorkupAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDefaultWorkupAnswer(
    params: {
      bpGuid: string;
      version: string;
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.saveDefaultWorkupAnswer$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadWorkupAnswer
   */
  static readonly LoadWorkupAnswerPath = '/workup/answers/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupAnswer()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswer$Response(
    params: {
      orderGuid: string;
      isOriginalForm: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupAnswer>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.LoadWorkupAnswerPath, 'get');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.query('isOriginalForm', params.isOriginalForm, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupAnswer>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupAnswer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswer(
    params: {
      orderGuid: string;
      isOriginalForm: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupAnswer> {
    return this.loadWorkupAnswer$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>) => r.body as WorkupAnswer)
    );
  }

  /**
   * Path part for operation saveWorkupAnswer
   */
  static readonly SaveWorkupAnswerPath = '/workup/answers/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkupAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswer$Response(
    params: {
      orderGuid: string;
      isOriginal?: boolean;
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.SaveWorkupAnswerPath, 'post');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.query('isOriginal', params.isOriginal, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: parseFloat(String((r as HttpResponse<any>).body)),
          }) as StrictHttpResponse<number>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveWorkupAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswer(
    params: {
      orderGuid: string;
      isOriginal?: boolean;
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<number> {
    return this.saveWorkupAnswer$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getWorkupFormCurrentVersion
   */
  static readonly GetWorkupFormCurrentVersionPath = '/workup/{inquiryCode}/current-version';

  /**
   * returns the current version of a form based on inquiry code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkupFormCurrentVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupFormCurrentVersion$Response(
    params: {
      inquiryCode:
        | 'DNRIDM'
        | 'MATID2'
        | 'MSQMS2'
        | 'FHQFH2'
        | 'CBUID2'
        | 'EWU'
        | 'SECEWU'
        | 'EIDMs'
        | 'CD34ST'
        | 'CD3ST'
        | 'CFU'
        | 'CFUST'
        | 'NRBCST'
        | 'TNCST'
        | 'VIAB'
        | 'VIABST'
        | 'NATHCV'
        | 'NATHIV'
        | 'EOR'
        | 'PROCES';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.GetWorkupFormCurrentVersionPath, 'get');
    if (params) {
      rb.path('inquiryCode', params.inquiryCode, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * returns the current version of a form based on inquiry code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorkupFormCurrentVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupFormCurrentVersion(
    params: {
      inquiryCode:
        | 'DNRIDM'
        | 'MATID2'
        | 'MSQMS2'
        | 'FHQFH2'
        | 'CBUID2'
        | 'EWU'
        | 'SECEWU'
        | 'EIDMs'
        | 'CD34ST'
        | 'CD3ST'
        | 'CFU'
        | 'CFUST'
        | 'NRBCST'
        | 'TNCST'
        | 'VIAB'
        | 'VIABST'
        | 'NATHCV'
        | 'NATHIV'
        | 'EOR'
        | 'PROCES';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<string> {
    return this.getWorkupFormCurrentVersion$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation loadWorkupFormStructure
   */
  static readonly LoadWorkupFormStructurePath = '/workup/structure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupFormStructure()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  loadWorkupFormStructure$Response(
    params?: {
      source?: string;
      version?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupForm>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.LoadWorkupFormStructurePath, 'get');
    if (params) {
      rb.query('source', params.source, {});
      rb.query('version', params.version, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupForm>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupFormStructure$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  loadWorkupFormStructure(
    params?: {
      source?: string;
      version?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupForm> {
    return this.loadWorkupFormStructure$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupForm>) => r.body as WorkupForm)
    );
  }

  /**
   * Path part for operation loadWorkupFormStructure1
   */
  static readonly LoadWorkupFormStructure1Path = '/workup/structure/{inquiryCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupFormStructure1()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupFormStructure1$Response(
    params: {
      inquiryCode:
        | 'DNRIDM'
        | 'MATID2'
        | 'MSQMS2'
        | 'FHQFH2'
        | 'CBUID2'
        | 'EWU'
        | 'SECEWU'
        | 'EIDMs'
        | 'CD34ST'
        | 'CD3ST'
        | 'CFU'
        | 'CFUST'
        | 'NRBCST'
        | 'TNCST'
        | 'VIAB'
        | 'VIABST'
        | 'NATHCV'
        | 'NATHIV'
        | 'EOR'
        | 'PROCES';
      version?: string;
      structureType?: 'EDIT' | 'VIEW';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupForm>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.LoadWorkupFormStructure1Path, 'get');
    if (params) {
      rb.path('inquiryCode', params.inquiryCode, {});
      rb.query('version', params.version, {});
      rb.query('structureType', params.structureType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupForm>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupFormStructure1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupFormStructure1(
    params: {
      inquiryCode:
        | 'DNRIDM'
        | 'MATID2'
        | 'MSQMS2'
        | 'FHQFH2'
        | 'CBUID2'
        | 'EWU'
        | 'SECEWU'
        | 'EIDMs'
        | 'CD34ST'
        | 'CD3ST'
        | 'CFU'
        | 'CFUST'
        | 'NRBCST'
        | 'TNCST'
        | 'VIAB'
        | 'VIABST'
        | 'NATHCV'
        | 'NATHIV'
        | 'EOR'
        | 'PROCES';
      version?: string;
      structureType?: 'EDIT' | 'VIEW';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupForm> {
    return this.loadWorkupFormStructure1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupForm>) => r.body as WorkupForm)
    );
  }

  /**
   * Path part for operation publishCreateRecipientPrescription
   */
  static readonly PublishCreateRecipientPrescriptionPath = '/workup/publish/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishCreateRecipientPrescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription$Response(
    params: {
      orderGuid: string;
      serviceCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.PublishCreateRecipientPrescriptionPath, 'get');
    if (params) {
      rb.query('orderGuid', params.orderGuid, {});
      rb.query('serviceCode', params.serviceCode, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publishCreateRecipientPrescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription(
    params: {
      orderGuid: string;
      serviceCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.publishCreateRecipientPrescription$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadWorkupAnswerInProgress
   */
  static readonly LoadWorkupAnswerInProgressPath = '/workup/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupAnswerInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgress$Response(
    params: {
      patientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<WorkupInProgressInfo>>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.LoadWorkupAnswerInProgressPath, 'get');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WorkupInProgressInfo>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadWorkupAnswerInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgress(
    params: {
      patientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<WorkupInProgressInfo>> {
    return this.loadWorkupAnswerInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<WorkupInProgressInfo>>) => r.body as Array<WorkupInProgressInfo>)
    );
  }

  /**
   * Path part for operation removeWorkupAnswerInProgress
   */
  static readonly RemoveWorkupAnswerInProgressPath = '/workup/in-progress/answers/{workupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeWorkupAnswerInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupAnswerInProgress$Response(
    params: {
      workupId: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WorkupControllerService.RemoveWorkupAnswerInProgressPath, 'delete');
    if (params) {
      rb.path('workupId', params.workupId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeWorkupAnswerInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupAnswerInProgress(
    params: {
      workupId: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.removeWorkupAnswerInProgress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
