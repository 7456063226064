/* tslint:disable */
/* eslint-disable */
import {NgModule, ModuleWithProviders, SkipSelf, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConfiguration, ApiConfigurationParams} from './api-configuration';

import {WorkupControllerService} from './services/workup-controller.service';
import {WorkupEnhancedControllerService} from './services/workup-enhanced-controller.service';
import {VaccinationControllerService} from './services/vaccination-controller.service';
import {SecondaryCodeControllerService} from './services/secondary-code-controller.service';
import {RecipientRelatedDonorControllerService} from './services/recipient-related-donor-controller.service';
import {RecipientControllerService} from './services/recipient-controller.service';
import {InternationalFormDocumentControllerService} from './services/international-form-document-controller.service';
import {InternationalFormDocumentNewWorkupControllerService} from './services/international-form-document-new-workup-controller.service';
import {FormDueControllerService} from './services/form-due-controller.service';
import {CoopDonorControllerService} from './services/coop-donor-controller.service';
import {CoopCordControllerService} from './services/coop-cord-controller.service';
import {CopyWorkupControllerService} from './services/copy-workup-controller.service';
import {UnitReportControllerService} from './services/unit-report-controller.service';
import {HlaTypingControllerService} from './services/hla-typing-controller.service';
import {SourceBaseInfoControllerService} from './services/source-base-info-controller.service';
import {LookupControllerService} from './services/lookup-controller.service';
import {AdvancedLookupControllerService} from './services/advanced-lookup-controller.service';
import {ExportControllerService} from './services/export-controller.service';
import {DuplicatePatientControllerService} from './services/duplicate-patient-controller.service';
import {DonorControllerService} from './services/donor-controller.service';
import {CordControllerService} from './services/cord-controller.service';
import {AntibodyProfileControllerService} from './services/antibody-profile-controller.service';
import {AnomalyDetectionControllerService} from './services/anomaly-detection-controller.service';
import {TestResultControllerService} from './services/test-result-controller.service';
import {SecurityControllerService} from './services/security-controller.service';
import {NomenclaturesControllerService} from './services/nomenclatures-controller.service';
import {LabReportControllerService} from './services/lab-report-controller.service';
import {BdpControllerService} from './services/bdp-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    WorkupControllerService,
    WorkupEnhancedControllerService,
    VaccinationControllerService,
    SecondaryCodeControllerService,
    RecipientRelatedDonorControllerService,
    RecipientControllerService,
    InternationalFormDocumentControllerService,
    InternationalFormDocumentNewWorkupControllerService,
    FormDueControllerService,
    CoopDonorControllerService,
    CoopCordControllerService,
    CopyWorkupControllerService,
    UnitReportControllerService,
    HlaTypingControllerService,
    SourceBaseInfoControllerService,
    LookupControllerService,
    AdvancedLookupControllerService,
    ExportControllerService,
    DuplicatePatientControllerService,
    DonorControllerService,
    CordControllerService,
    AntibodyProfileControllerService,
    AnomalyDetectionControllerService,
    TestResultControllerService,
    SecurityControllerService,
    NomenclaturesControllerService,
    LabReportControllerService,
    BdpControllerService,
    ApiConfiguration,
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
