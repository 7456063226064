/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Cord } from '../models/cord';
import { EthnicityRaceInfo } from '../models/ethnicity-race-info';
import { Form } from '../models/form';
import { Kir } from '../models/kir';
import { ProcessingProcedure } from '../models/processing-procedure';

@Injectable({
  providedIn: 'root',
})
export class CordControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCordsByGuids
   */
  static readonly GetCordsByGuidsPath = '/cords';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordsByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordsByGuids$Response(params: {
    ignoreError?: boolean;
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Cord>>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetCordsByGuidsPath, 'post');
    if (params) {
      rb.query('ignoreError', params.ignoreError, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Cord>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCordsByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordsByGuids(params: {
    ignoreError?: boolean;
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Cord>> {

    return this.getCordsByGuids$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Cord>>) => r.body as Array<Cord>)
    );
  }

  /**
   * Path part for operation getProcessingProcedure
   */
  static readonly GetProcessingProcedurePath = '/cords/processing-procedure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessingProcedure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProcessingProcedure$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: ProcessingProcedure;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetProcessingProcedurePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: ProcessingProcedure;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProcessingProcedure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProcessingProcedure(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: ProcessingProcedure;
}> {

    return this.getProcessingProcedure$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: ProcessingProcedure;
}>) => r.body as {
[key: string]: ProcessingProcedure;
})
    );
  }

  /**
   * Path part for operation getCordEthnicityRaceInBulk
   */
  static readonly GetCordEthnicityRaceInBulkPath = '/cords/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordEthnicityRaceInBulk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordEthnicityRaceInBulk$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetCordEthnicityRaceInBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: EthnicityRaceInfo;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCordEthnicityRaceInBulk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordEthnicityRaceInBulk(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: EthnicityRaceInfo;
}> {

    return this.getCordEthnicityRaceInBulk$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>) => r.body as {
[key: string]: EthnicityRaceInfo;
})
    );
  }

  /**
   * Path part for operation getCordByGuid
   */
  static readonly GetCordByGuidPath = '/cords/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordByGuid$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Cord>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetCordByGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cord>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCordByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordByGuid(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Cord> {

    return this.getCordByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Cord>) => r.body as Cord)
    );
  }

  /**
   * Path part for operation getKir1
   */
  static readonly GetKir1Path = '/cords/{guid}/kir';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKir1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir1$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Kir>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetKir1Path, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Kir>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getKir1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir1(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Kir> {

    return this.getKir1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Kir>) => r.body as Kir)
    );
  }

  /**
   * Path part for operation getCordIdms
   */
  static readonly GetCordIdmsPath = '/cords/{guid}/idms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordIdms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordIdms$Response(params: {
    guid: string;
    maternal?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Form>>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetCordIdmsPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('maternal', params.maternal, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Form>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCordIdms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordIdms(params: {
    guid: string;
    maternal?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Form>> {

    return this.getCordIdms$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Form>>) => r.body as Array<Form>)
    );
  }

  /**
   * Path part for operation getFullCordByGuid
   */
  static readonly GetFullCordByGuidPath = '/cords/{guid}/full';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullCordByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullCordByGuid$Response(params: {
    guid: string;
    cordIdm?: boolean;
    maternalCordIdm?: boolean;
    additionalTestForms?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Cord>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetFullCordByGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('cordIdm', params.cordIdm, {});
      rb.query('maternalCordIdm', params.maternalCordIdm, {});
      rb.query('additionalTestForms', params.additionalTestForms, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cord>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullCordByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullCordByGuid(params: {
    guid: string;
    cordIdm?: boolean;
    maternalCordIdm?: boolean;
    additionalTestForms?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Cord> {

    return this.getFullCordByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Cord>) => r.body as Cord)
    );
  }

  /**
   * Path part for operation getCordEthnicityRace
   */
  static readonly GetCordEthnicityRacePath = '/cords/{guid}/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordEthnicityRace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordEthnicityRace$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<EthnicityRaceInfo>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetCordEthnicityRacePath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EthnicityRaceInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCordEthnicityRace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordEthnicityRace(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<EthnicityRaceInfo> {

    return this.getCordEthnicityRace$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<EthnicityRaceInfo>) => r.body as EthnicityRaceInfo)
    );
  }

  /**
   * Path part for operation getAdditionalTestForms
   */
  static readonly GetAdditionalTestFormsPath = '/cords/{guid}/additional-tests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdditionalTestForms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalTestForms$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Form>>> {

    const rb = new RequestBuilder(this.rootUrl, CordControllerService.GetAdditionalTestFormsPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Form>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdditionalTestForms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalTestForms(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Form>> {

    return this.getAdditionalTestForms$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Form>>) => r.body as Array<Form>)
    );
  }

}
