/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CoopCord } from '../models/coop-cord';
import { CreateCoopSourceResult } from '../models/create-coop-source-result';
import { UpdateCoopSourceResult } from '../models/update-coop-source-result';

@Injectable({
  providedIn: 'root',
})
export class CoopCordControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCoopCord
   */
  static readonly GetCoopCordPath = '/cords/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopCord()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCord$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<CoopCord>> {

    const rb = new RequestBuilder(this.rootUrl, CoopCordControllerService.GetCoopCordPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoopCord>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCoopCord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCord(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<CoopCord> {

    return this.getCoopCord$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CoopCord>) => r.body as CoopCord)
    );
  }

  /**
   * Path part for operation updateCoopCord
   */
  static readonly UpdateCoopCordPath = '/cords/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCoopCord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCoopCord$Response(params: {
    guid: string;
    context?: HttpContext
    body: CoopCord
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<UpdateCoopSourceResult>> {

    const rb = new RequestBuilder(this.rootUrl, CoopCordControllerService.UpdateCoopCordPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCoopSourceResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCoopCord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCoopCord(params: {
    guid: string;
    context?: HttpContext
    body: CoopCord
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<UpdateCoopSourceResult> {

    return this.updateCoopCord$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<UpdateCoopSourceResult>) => r.body as UpdateCoopSourceResult)
    );
  }

  /**
   * Path part for operation getCoopCords
   */
  static readonly GetCoopCordsPath = '/cords/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopCords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCords$Response(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CoopCord>>> {

    const rb = new RequestBuilder(this.rootUrl, CoopCordControllerService.GetCoopCordsPath, 'get');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CoopCord>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCoopCords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCords(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CoopCord>> {

    return this.getCoopCords$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CoopCord>>) => r.body as Array<CoopCord>)
    );
  }

  /**
   * Path part for operation createCoopCord
   */
  static readonly CreateCoopCordPath = '/cords/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCoopCord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCoopCord$Response(params: {
    context?: HttpContext
    body: CoopCord
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<CreateCoopSourceResult>> {

    const rb = new RequestBuilder(this.rootUrl, CoopCordControllerService.CreateCoopCordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCoopSourceResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCoopCord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCoopCord(params: {
    context?: HttpContext
    body: CoopCord
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<CreateCoopSourceResult> {

    return this.createCoopCord$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CreateCoopSourceResult>) => r.body as CreateCoopSourceResult)
    );
  }

  /**
   * Path part for operation publishAddMatchedCord
   */
  static readonly PublishAddMatchedCordPath = '/cords/coop/publish/matched/{cordGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishAddMatchedCord()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedCord$Response(params: {
    cordGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoopCordControllerService.PublishAddMatchedCordPath, 'get');
    if (params) {
      rb.path('cordGuid', params.cordGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publishAddMatchedCord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedCord(params: {
    cordGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.publishAddMatchedCord$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
