/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {ActiveSearchResponse} from '../models/active-search-response';
import {CloseRequest} from '../models/close-request';
import {JsonNode} from '../models/json-node';
import {LiteRecipient} from '../models/lite-recipient';
import {LiteRecipientResponse} from '../models/lite-recipient-response';
import {Phenotype} from '../models/phenotype';
import {PrimaryRace} from '../models/primary-race';
import {RaceEthnicityHistory} from '../models/race-ethnicity-history';
import {Recipient} from '../models/recipient';
import {RecipientClosedHistory} from '../models/recipient-closed-history';
import {RecipientIdentityVerificationInfo} from '../models/recipient-identity-verification-info';
import {RecipientIdentityVerifyHistory} from '../models/recipient-identity-verify-history';
import {RecipientTransferHistory} from '../models/recipient-transfer-history';
import {ReferralPlan} from '../models/referral-plan';
import {Result} from '../models/result';
import {RunRequest} from '../models/run-request';
import {SearchParameter} from '../models/search-parameter';
import {SearchStatusRequest} from '../models/search-status-request';
import {SelfAntibodyResult} from '../models/self-antibody-result';
import {SubjectIdentifier} from '../models/subject-identifier';
import {TransferRecipientRequest} from '../models/transfer-recipient-request';
import {TransplantTimelineDatesResponse} from '../models/transplant-timeline-dates-response';
import {TransplantTimelineHistory} from '../models/transplant-timeline-history';
import {TypingFrequenciesExistResponse} from '../models/typing-frequencies-exist-response';
import {TypingFrequency} from '../models/typing-frequency';

@Injectable({
  providedIn: 'root',
})
export class RecipientControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getRecipient
   */
  static readonly GetRecipientPath = '/recipients/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipient$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Recipient>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Recipient>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipient(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Recipient> {
    return this.getRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Recipient>) => r.body as Recipient)
    );
  }

  /**
   * Path part for operation updateRecipient
   */
  static readonly UpdateRecipientPath = '/recipients/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipient$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: Recipient;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Result>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.UpdateRecipientPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Result>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipient(
    params: {
      guid: string;
      context?: HttpContext;
      body: Recipient;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Result> {
    return this.updateRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Result>) => r.body as Result)
    );
  }

  /**
   * Path part for operation deleteInProgressRecipient
   */
  static readonly DeleteInProgressRecipientPath = '/recipients/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInProgressRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInProgressRecipient$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.DeleteInProgressRecipientPath, 'delete');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteInProgressRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInProgressRecipient(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{}> {
    return this.deleteInProgressRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{}>) => r.body as {})
    );
  }

  /**
   * Path part for operation updateTransplantTimeline
   */
  static readonly UpdateTransplantTimelinePath = '/recipients/{guid}/transplant-timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTransplantTimeline()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTransplantTimeline$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: string;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.UpdateTransplantTimelinePath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTransplantTimeline$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTransplantTimeline(
    params: {
      guid: string;
      context?: HttpContext;
      body: string;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateTransplantTimeline$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateRecipientStatus
   */
  static readonly UpdateRecipientStatusPath = '/recipients/{guid}/status/{statusCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipientStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientStatus$Response(
    params: {
      guid: string;
      statusCode: string;
      context?: HttpContext;
      body: Array<SearchParameter>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.UpdateRecipientStatusPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.path('statusCode', params.statusCode, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRecipientStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientStatus(
    params: {
      guid: string;
      statusCode: string;
      context?: HttpContext;
      body: Array<SearchParameter>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateRecipientStatus$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setLatestShareHistoryPublishedFlagTrue
   */
  static readonly SetLatestShareHistoryPublishedFlagTruePath = '/recipients/{guid}/set-share-history-published';

  /**
   * Set published flag true of the latest share history  after search summary report has been published for recipient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLatestShareHistoryPublishedFlagTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  setLatestShareHistoryPublishedFlagTrue$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientControllerService.SetLatestShareHistoryPublishedFlagTruePath,
      'put'
    );
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Set published flag true of the latest share history  after search summary report has been published for recipient
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setLatestShareHistoryPublishedFlagTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setLatestShareHistoryPublishedFlagTrue(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.setLatestShareHistoryPublishedFlagTrue$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reactivateRecipient
   */
  static readonly ReactivateRecipientPath = '/recipients/{guid}/reactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reactivateRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reactivateRecipient$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: Array<SearchParameter>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.ReactivateRecipientPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reactivateRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reactivateRecipient(
    params: {
      guid: string;
      context?: HttpContext;
      body: Array<SearchParameter>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.reactivateRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateRecipientPartially
   */
  static readonly UpdateRecipientPartiallyPath = '/recipients/{guid}/partially';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipientPartially()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientPartially$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: {
        [key: string]: string;
      };
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Result>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.UpdateRecipientPartiallyPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Result>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRecipientPartially$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientPartially(
    params: {
      guid: string;
      context?: HttpContext;
      body: {
        [key: string]: string;
      };
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Result> {
    return this.updateRecipientPartially$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Result>) => r.body as Result)
    );
  }

  /**
   * Path part for operation updateRecipientPartiallyBasedOnWorkupOrder
   */
  static readonly UpdateRecipientPartiallyBasedOnWorkupOrderPath =
    '/recipients/{guid}/partially/workup-order/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipientPartiallyBasedOnWorkupOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRecipientPartiallyBasedOnWorkupOrder$Response(
    params: {
      guid: string;
      orderGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Result>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientControllerService.UpdateRecipientPartiallyBasedOnWorkupOrderPath,
      'put'
    );
    if (params) {
      rb.path('guid', params.guid, {});
      rb.path('orderGuid', params.orderGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Result>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRecipientPartiallyBasedOnWorkupOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRecipientPartiallyBasedOnWorkupOrder(
    params: {
      guid: string;
      orderGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Result> {
    return this.updateRecipientPartiallyBasedOnWorkupOrder$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Result>) => r.body as Result)
    );
  }

  /**
   * Path part for operation updateReferralPlan
   */
  static readonly UpdateReferralPlanPath = '/recipients/referral/plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReferralPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferralPlan$Response(
    params: {
      context?: HttpContext;
      body: ReferralPlan;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.UpdateReferralPlanPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateReferralPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferralPlan(
    params: {
      context?: HttpContext;
      body: ReferralPlan;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateReferralPlan$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addRecipient
   */
  static readonly AddRecipientPath = '/recipients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipient$Response(
    params: {
      context?: HttpContext;
      body: Recipient;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Result>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.AddRecipientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Result>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipient(
    params: {
      context?: HttpContext;
      body: Recipient;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Result> {
    return this.addRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Result>) => r.body as Result)
    );
  }

  /**
   * Path part for operation transferRecipient
   */
  static readonly TransferRecipientPath = '/recipients/{guid}/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferRecipient$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: TransferRecipientRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.TransferRecipientPath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transferRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferRecipient(
    params: {
      guid: string;
      context?: HttpContext;
      body: TransferRecipientRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.transferRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation runSearch
   */
  static readonly RunSearchPath = '/recipients/{guid}/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSearch$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: RunRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<
    StrictHttpResponse<{
      [key: string]: string;
    }>
  > {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.RunSearchPath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            [key: string]: string;
          }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSearch(
    params: {
      guid: string;
      context?: HttpContext;
      body: RunRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{
    [key: string]: string;
  }> {
    return this.runSearch$Response(params, requestOptions).pipe(
      map(
        (
          r: StrictHttpResponse<{
            [key: string]: string;
          }>
        ) =>
          r.body as {
            [key: string]: string;
          }
      )
    );
  }

  /**
   * Path part for operation getReferralPlansByRecipientGuid
   */
  static readonly GetReferralPlansByRecipientGuidPath = '/recipients/{guid}/referral/plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferralPlansByRecipientGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlansByRecipientGuid$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<ReferralPlan>>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetReferralPlansByRecipientGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ReferralPlan>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReferralPlansByRecipientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlansByRecipientGuid(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<ReferralPlan>> {
    return this.getReferralPlansByRecipientGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ReferralPlan>>) => r.body as Array<ReferralPlan>)
    );
  }

  /**
   * Path part for operation addReferralPlan
   */
  static readonly AddReferralPlanPath = '/recipients/{guid}/referral/plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addReferralPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReferralPlan$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: ReferralPlan;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<ReferralPlan>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.AddReferralPlanPath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ReferralPlan>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addReferralPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReferralPlan(
    params: {
      guid: string;
      context?: HttpContext;
      body: ReferralPlan;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<ReferralPlan> {
    return this.addReferralPlan$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<ReferralPlan>) => r.body as ReferralPlan)
    );
  }

  /**
   * Path part for operation getAndSavePrimaryRace
   */
  static readonly GetAndSavePrimaryRacePath = '/recipients/{guid}/primary-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAndSavePrimaryRace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAndSavePrimaryRace$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<PrimaryRace>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetAndSavePrimaryRacePath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PrimaryRace>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAndSavePrimaryRace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAndSavePrimaryRace(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<PrimaryRace> {
    return this.getAndSavePrimaryRace$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PrimaryRace>) => r.body as PrimaryRace)
    );
  }

  /**
   * Path part for operation closeRecipient
   */
  static readonly CloseRecipientPath = '/recipients/{guid}/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeRecipient$Response(
    params: {
      guid: string;
      context?: HttpContext;
      body: CloseRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.CloseRecipientPath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `closeRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeRecipient(
    params: {
      guid: string;
      context?: HttpContext;
      body: CloseRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.closeRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addLiteRecipient
   */
  static readonly AddLiteRecipientPath = '/recipients/lite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLiteRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLiteRecipient$Response(
    params: {
      context?: HttpContext;
      body: LiteRecipient;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<LiteRecipientResponse>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.AddLiteRecipientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LiteRecipientResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLiteRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLiteRecipient(
    params: {
      context?: HttpContext;
      body: LiteRecipient;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<LiteRecipientResponse> {
    return this.addLiteRecipient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<LiteRecipientResponse>) => r.body as LiteRecipientResponse)
    );
  }

  /**
   * Path part for operation generateId
   */
  static readonly GenerateIdPath = '/recipients/generateId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateId$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GenerateIdPath, 'post');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: parseFloat(String((r as HttpResponse<any>).body)),
          }) as StrictHttpResponse<number>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateId(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<number> {
    return this.generateId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation addOrUpdateRecipientExternal
   */
  static readonly AddOrUpdateRecipientExternalPath = '/recipients/external';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrUpdateRecipientExternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateRecipientExternal$Response(
    params: {
      context?: HttpContext;
      body: JsonNode;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.AddOrUpdateRecipientExternalPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOrUpdateRecipientExternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateRecipientExternal(
    params: {
      context?: HttpContext;
      body: JsonNode;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.addOrUpdateRecipientExternal$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation rerunExternalIfNecessary
   */
  static readonly RerunExternalIfNecessaryPath = '/recipients/external/search/rerunIfNecessary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rerunExternalIfNecessary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunExternalIfNecessary$Response(
    params: {
      context?: HttpContext;
      body: SearchStatusRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<ActiveSearchResponse>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.RerunExternalIfNecessaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ActiveSearchResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rerunExternalIfNecessary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunExternalIfNecessary(
    params: {
      context?: HttpContext;
      body: SearchStatusRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<ActiveSearchResponse> {
    return this.rerunExternalIfNecessary$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<ActiveSearchResponse>) => r.body as ActiveSearchResponse)
    );
  }

  /**
   * Path part for operation isRecipientClosed
   */
  static readonly IsRecipientClosedPath = '/recipients/{recipientGuid}/is-closed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isRecipientClosed()` instead.
   *
   * This method doesn't expect any request body.
   */
  isRecipientClosed$Response(
    params: {
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.IsRecipientClosedPath, 'get');
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isRecipientClosed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isRecipientClosed(
    params: {
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<boolean> {
    return this.isRecipientClosed$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getTypingFrequency
   */
  static readonly GetTypingFrequencyPath = '/recipients/{guid}/typing-frequency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTypingFrequency()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingFrequency$Response(
    params: {
      guid: string;
      phenotypeNum?: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<TypingFrequency>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetTypingFrequencyPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('phenotypeNum', params.phenotypeNum, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TypingFrequency>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTypingFrequency$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingFrequency(
    params: {
      guid: string;
      phenotypeNum?: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<TypingFrequency> {
    return this.getTypingFrequency$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<TypingFrequency>) => r.body as TypingFrequency)
    );
  }

  /**
   * Path part for operation isTypingFrequencyExist
   */
  static readonly IsTypingFrequencyExistPath = '/recipients/{guid}/typing-frequency/exist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isTypingFrequencyExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  isTypingFrequencyExist$Response(
    params: {
      guid: string;
      phenotypeNum: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<TypingFrequenciesExistResponse>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.IsTypingFrequencyExistPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('phenotypeNum', params.phenotypeNum, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TypingFrequenciesExistResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isTypingFrequencyExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isTypingFrequencyExist(
    params: {
      guid: string;
      phenotypeNum: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<TypingFrequenciesExistResponse> {
    return this.isTypingFrequencyExist$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<TypingFrequenciesExistResponse>) => r.body as TypingFrequenciesExistResponse)
    );
  }

  /**
   * Path part for operation getTransplantTimelineDates
   */
  static readonly GetTransplantTimelineDatesPath = '/recipients/{guid}/transplant-timeline-dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransplantTimelineDates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransplantTimelineDates$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<TransplantTimelineDatesResponse>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetTransplantTimelineDatesPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TransplantTimelineDatesResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTransplantTimelineDates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransplantTimelineDates(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<TransplantTimelineDatesResponse> {
    return this.getTransplantTimelineDates$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<TransplantTimelineDatesResponse>) => r.body as TransplantTimelineDatesResponse)
    );
  }

  /**
   * Path part for operation getRecipientTcGuid
   */
  static readonly GetRecipientTcGuidPath = '/recipients/{guid}/tc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientTcGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTcGuid$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientTcGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientTcGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTcGuid(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<string> {
    return this.getRecipientTcGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSelfAntibodies
   */
  static readonly GetSelfAntibodiesPath = '/recipients/{guid}/self-antibodies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelfAntibodies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfAntibodies$Response(
    params: {
      guid: string;
      phenotypeNum: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<SelfAntibodyResult>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetSelfAntibodiesPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('phenotypeNum', params.phenotypeNum, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SelfAntibodyResult>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSelfAntibodies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfAntibodies(
    params: {
      guid: string;
      phenotypeNum: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<SelfAntibodyResult> {
    return this.getSelfAntibodies$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SelfAntibodyResult>) => r.body as SelfAntibodyResult)
    );
  }

  /**
   * Path part for operation getRecipientPtr
   */
  static readonly GetRecipientPtrPath = '/recipients/{guid}/ptr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientPtr$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Plain$Response(
    params: {
      guid: string;
      phenotypeId?: number;
      typingsOnly?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientPtrPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('phenotypeId', params.phenotypeId, {});
      rb.query('typingsOnly', params.typingsOnly, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientPtr$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Plain(
    params: {
      guid: string;
      phenotypeId?: number;
      typingsOnly?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{}> {
    return this.getRecipientPtr$Plain$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{}>) => r.body as {})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientPtr$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Json$Response(
    params: {
      guid: string;
      phenotypeId?: number;
      typingsOnly?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientPtrPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('phenotypeId', params.phenotypeId, {});
      rb.query('typingsOnly', params.typingsOnly, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientPtr$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Json(
    params: {
      guid: string;
      phenotypeId?: number;
      typingsOnly?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{}> {
    return this.getRecipientPtr$Json$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{}>) => r.body as {})
    );
  }

  /**
   * Path part for operation getPhenotype
   */
  static readonly GetPhenotypePath = '/recipients/{guid}/phenotypes/{phenotypeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPhenotype()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhenotype$Response(
    params: {
      guid: string;
      phenotypeId: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Phenotype>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetPhenotypePath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.path('phenotypeId', params.phenotypeId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Phenotype>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPhenotype$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhenotype(
    params: {
      guid: string;
      phenotypeId: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Phenotype> {
    return this.getPhenotype$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Phenotype>) => r.body as Phenotype)
    );
  }

  /**
   * Path part for operation getRecipientIdentityVerificationInfo
   */
  static readonly GetRecipientIdentityVerificationInfoPath = '/recipients/{guid}/identity-verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientIdentityVerificationInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerificationInfo$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<RecipientIdentityVerificationInfo>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientControllerService.GetRecipientIdentityVerificationInfoPath,
      'get'
    );
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RecipientIdentityVerificationInfo>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientIdentityVerificationInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerificationInfo(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<RecipientIdentityVerificationInfo> {
    return this.getRecipientIdentityVerificationInfo$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<RecipientIdentityVerificationInfo>) => r.body as RecipientIdentityVerificationInfo)
    );
  }

  /**
   * Path part for operation getRecipientIdentifiers
   */
  static readonly GetRecipientIdentifiersPath = '/recipients/{guid}/identifiers';

  /**
   * Retrieves all identifier of patient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientIdentifiers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentifiers$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<SubjectIdentifier>>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientIdentifiersPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<SubjectIdentifier>>;
        })
      );
  }

  /**
   * Retrieves all identifier of patient
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientIdentifiers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentifiers(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<SubjectIdentifier>> {
    return this.getRecipientIdentifiers$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<SubjectIdentifier>>) => r.body as Array<SubjectIdentifier>)
    );
  }

  /**
   * Path part for operation getRecipientTransplantTimelineHistory
   */
  static readonly GetRecipientTransplantTimelineHistoryPath = '/recipients/{guid}/history/transplant-timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientTransplantTimelineHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransplantTimelineHistory$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<TransplantTimelineHistory>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientControllerService.GetRecipientTransplantTimelineHistoryPath,
      'get'
    );
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TransplantTimelineHistory>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientTransplantTimelineHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransplantTimelineHistory(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<TransplantTimelineHistory>> {
    return this.getRecipientTransplantTimelineHistory$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<TransplantTimelineHistory>>) => r.body as Array<TransplantTimelineHistory>)
    );
  }

  /**
   * Path part for operation getRecipientTransferHistory
   */
  static readonly GetRecipientTransferHistoryPath = '/recipients/{guid}/history/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientTransferHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransferHistory$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<RecipientTransferHistory>>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientTransferHistoryPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RecipientTransferHistory>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientTransferHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransferHistory(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<RecipientTransferHistory>> {
    return this.getRecipientTransferHistory$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<RecipientTransferHistory>>) => r.body as Array<RecipientTransferHistory>)
    );
  }

  /**
   * Path part for operation getRecipientIdentityVerifyHistory
   */
  static readonly GetRecipientIdentityVerifyHistoryPath = '/recipients/{guid}/history/identity-verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientIdentityVerifyHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerifyHistory$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<RecipientIdentityVerifyHistory>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientControllerService.GetRecipientIdentityVerifyHistoryPath,
      'get'
    );
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RecipientIdentityVerifyHistory>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientIdentityVerifyHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerifyHistory(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<RecipientIdentityVerifyHistory>> {
    return this.getRecipientIdentityVerifyHistory$Response(params, requestOptions).pipe(
      map(
        (r: StrictHttpResponse<Array<RecipientIdentityVerifyHistory>>) =>
          r.body as Array<RecipientIdentityVerifyHistory>
      )
    );
  }

  /**
   * Path part for operation getRecipientClosedHistory
   */
  static readonly GetRecipientClosedHistoryPath = '/recipients/{guid}/history/closed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientClosedHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientClosedHistory$Response(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<RecipientClosedHistory>>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientClosedHistoryPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RecipientClosedHistory>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientClosedHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientClosedHistory(
    params: {
      guid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<RecipientClosedHistory>> {
    return this.getRecipientClosedHistory$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<RecipientClosedHistory>>) => r.body as Array<RecipientClosedHistory>)
    );
  }

  /**
   * Path part for operation getReferralPlan
   */
  static readonly GetReferralPlanPath = '/recipients/referral/plans/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferralPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlan$Response(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<ReferralPlan>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetReferralPlanPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ReferralPlan>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReferralPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlan(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<ReferralPlan> {
    return this.getReferralPlan$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<ReferralPlan>) => r.body as ReferralPlan)
    );
  }

  /**
   * Path part for operation deleteReferralPlan
   */
  static readonly DeleteReferralPlanPath = '/recipients/referral/plans/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReferralPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReferralPlan$Response(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.DeleteReferralPlanPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteReferralPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReferralPlan(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.deleteReferralPlan$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRecipientRaceEthnicityHistory
   */
  static readonly GetRecipientRaceEthnicityHistoryPath = '/recipients/race-ethnicity-history/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRaceEthnicityHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRaceEthnicityHistory$Response(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<RaceEthnicityHistory>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetRecipientRaceEthnicityHistoryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RaceEthnicityHistory>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientRaceEthnicityHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRaceEthnicityHistory(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<RaceEthnicityHistory> {
    return this.getRecipientRaceEthnicityHistory$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<RaceEthnicityHistory>) => r.body as RaceEthnicityHistory)
    );
  }

  /**
   * Path part for operation getLatestTcPrlmPatients
   */
  static readonly GetLatestTcPrlmPatientsPath = '/recipients/latest-prlm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestTcPrlmPatients()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestTcPrlmPatients$Response(
    params: {
      fromDate: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetLatestTcPrlmPatientsPath, 'get');
    if (params) {
      rb.query('fromDate', params.fromDate, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<string>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestTcPrlmPatients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestTcPrlmPatients(
    params: {
      fromDate: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<string>> {
    return this.getLatestTcPrlmPatients$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation checkIfLabRefOrLocalIdExist
   */
  static readonly CheckIfLabRefOrLocalIdExistPath = '/recipients/identifiers/exist/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfLabRefOrLocalIdExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfLabRefOrLocalIdExist$Response(
    params: {
      id: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.CheckIfLabRefOrLocalIdExistPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkIfLabRefOrLocalIdExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfLabRefOrLocalIdExist(
    params: {
      id: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<boolean> {
    return this.checkIfLabRefOrLocalIdExist$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getBpGuidByDocumentId
   */
  static readonly GetBpGuidByDocumentIdPath = '/recipients/documenttc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBpGuidByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBpGuidByDocumentId$Response(
    params: {
      owcId: string;
      featureType: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientControllerService.GetBpGuidByDocumentIdPath, 'get');
    if (params) {
      rb.query('owcId', params.owcId, {});
      rb.query('featureType', params.featureType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBpGuidByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBpGuidByDocumentId(
    params: {
      owcId: string;
      featureType: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<string> {
    return this.getBpGuidByDocumentId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }
}
