/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SecondaryCode } from '../models/secondary-code';

@Injectable({
  providedIn: 'root',
})
export class SecondaryCodeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllForTc
   */
  static readonly GetAllForTcPath = '/secondary-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllForTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllForTc$Response(params: {
    tcGuid: string;
    active?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<SecondaryCode>>> {

    const rb = new RequestBuilder(this.rootUrl, SecondaryCodeControllerService.GetAllForTcPath, 'get');
    if (params) {
      rb.query('tcGuid', params.tcGuid, {});
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SecondaryCode>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllForTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllForTc(params: {
    tcGuid: string;
    active?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<SecondaryCode>> {

    return this.getAllForTc$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<SecondaryCode>>) => r.body as Array<SecondaryCode>)
    );
  }

  /**
   * Path part for operation update
   */
  static readonly UpdatePath = '/secondary-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: {
    context?: HttpContext
    body: SecondaryCode
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SecondaryCodeControllerService.UpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: {
    context?: HttpContext
    body: SecondaryCode
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.update$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation create
   */
  static readonly CreatePath = '/secondary-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {
    context?: HttpContext
    body: SecondaryCode
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SecondaryCodeControllerService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {
    context?: HttpContext
    body: SecondaryCode
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.create$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation getByGuid
   */
  static readonly GetByGuidPath = '/secondary-codes/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByGuid$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SecondaryCode>> {

    const rb = new RequestBuilder(this.rootUrl, SecondaryCodeControllerService.GetByGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SecondaryCode>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByGuid(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SecondaryCode> {

    return this.getByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SecondaryCode>) => r.body as SecondaryCode)
    );
  }

  /**
   * Path part for operation getForTcAndSecondaryCode
   */
  static readonly GetForTcAndSecondaryCodePath = '/secondary-codes/find';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForTcAndSecondaryCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForTcAndSecondaryCode$Response(params: {
    tcGuid: string;
    secCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SecondaryCode>> {

    const rb = new RequestBuilder(this.rootUrl, SecondaryCodeControllerService.GetForTcAndSecondaryCodePath, 'get');
    if (params) {
      rb.query('tcGuid', params.tcGuid, {});
      rb.query('secCode', params.secCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SecondaryCode>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getForTcAndSecondaryCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForTcAndSecondaryCode(params: {
    tcGuid: string;
    secCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SecondaryCode> {

    return this.getForTcAndSecondaryCode$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SecondaryCode>) => r.body as SecondaryCode)
    );
  }

}
