/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SupplementsStaticsDto } from '../models/supplements-statics-dto';
import { VaccinationDto } from '../models/vaccination-dto';

@Injectable({
  providedIn: 'root',
})
export class VaccinationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateVaccinationData
   */
  static readonly UpdateVaccinationDataPath = '/vaccinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVaccinationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVaccinationData$Response(params: {
    context?: HttpContext
    body: Array<VaccinationDto>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationControllerService.UpdateVaccinationDataPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVaccinationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVaccinationData(params: {
    context?: HttpContext
    body: Array<VaccinationDto>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateVaccinationData$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getVaccinationsByGuid
   */
  static readonly GetVaccinationsByGuidPath = '/vaccinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationsByGuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVaccinationsByGuid$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<VaccinationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationControllerService.GetVaccinationsByGuidPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VaccinationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationsByGuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVaccinationsByGuid(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<VaccinationDto>> {

    return this.getVaccinationsByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<VaccinationDto>>) => r.body as Array<VaccinationDto>)
    );
  }

  /**
   * Path part for operation createVaccinationData
   */
  static readonly CreateVaccinationDataPath = '/vaccinations/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVaccinationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVaccinationData$Response(params: {
    context?: HttpContext
    body: Array<VaccinationDto>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationControllerService.CreateVaccinationDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createVaccinationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVaccinationData(params: {
    context?: HttpContext
    body: Array<VaccinationDto>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.createVaccinationData$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCatalog
   */
  static readonly GetCatalogPath = '/vaccinations/catalog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalog$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SupplementsStaticsDto>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationControllerService.GetCatalogPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplementsStaticsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCatalog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalog(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SupplementsStaticsDto> {

    return this.getCatalog$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SupplementsStaticsDto>) => r.body as SupplementsStaticsDto)
    );
  }

}
