/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CollectionStringFilter } from '../models/collection-string-filter';
import { LocalDateAndTimeZoneFilter } from '../models/local-date-and-time-zone-filter';
import { LocalDateFilter } from '../models/local-date-filter';
import { LookupResultsRequest } from '../models/lookup-results-request';
import { PageRecipientAdvancedLookupResult } from '../models/page-recipient-advanced-lookup-result';
import { PatientStatusFilter } from '../models/patient-status-filter';
import { RecipientAdvancedLookupResult } from '../models/recipient-advanced-lookup-result';
import { StringFilter } from '../models/string-filter';

@Injectable({
  providedIn: 'root',
})
export class AdvancedLookupControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation lookupRecipientsPageBy
   */
  static readonly LookupRecipientsPageByPath = '/lookup/advanced/recipient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupRecipientsPageBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientsPageBy$Response(params: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: Array<(CollectionStringFilter | LocalDateAndTimeZoneFilter | LocalDateFilter | PatientStatusFilter | StringFilter)>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageRecipientAdvancedLookupResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdvancedLookupControllerService.LookupRecipientsPageByPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageRecipientAdvancedLookupResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupRecipientsPageBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientsPageBy(params: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: Array<(CollectionStringFilter | LocalDateAndTimeZoneFilter | LocalDateFilter | PatientStatusFilter | StringFilter)>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageRecipientAdvancedLookupResult> {

    return this.lookupRecipientsPageBy$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageRecipientAdvancedLookupResult>) => r.body as PageRecipientAdvancedLookupResult)
    );
  }

  /**
   * Path part for operation lookupRecipientsListBy
   */
  static readonly LookupRecipientsListByPath = '/lookup/advanced/recipient/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupRecipientsListBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientsListBy$Response(params: {
    context?: HttpContext
    body: LookupResultsRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<RecipientAdvancedLookupResult>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvancedLookupControllerService.LookupRecipientsListByPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RecipientAdvancedLookupResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupRecipientsListBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientsListBy(params: {
    context?: HttpContext
    body: LookupResultsRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<RecipientAdvancedLookupResult>> {

    return this.lookupRecipientsListBy$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<RecipientAdvancedLookupResult>>) => r.body as Array<RecipientAdvancedLookupResult>)
    );
  }

}
