/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {WorkupAnswer} from '../models/workup-answer';

@Injectable({
  providedIn: 'root',
})
export class CopyWorkupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation saveCopyOfWorkupForm
   */
  static readonly SaveCopyOfWorkupFormPath = '/workup-copy/answers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCopyOfWorkupForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCopyOfWorkupForm$Response(
    params: {
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CopyWorkupControllerService.SaveCopyOfWorkupFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCopyOfWorkupForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCopyOfWorkupForm(
    params: {
      context?: HttpContext;
      body: WorkupAnswer;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.saveCopyOfWorkupForm$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCopyOfWorkupFormByPatientGuid
   */
  static readonly GetCopyOfWorkupFormByPatientGuidPath = '/workup-copy/answers/{patientGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCopyOfWorkupFormByPatientGuid()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCopyOfWorkupFormByPatientGuid$Response(
    params: {
      patientGuid: string;
      sourceType: 'DONOR' | 'CORD' | 'BDP';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupAnswer>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CopyWorkupControllerService.GetCopyOfWorkupFormByPatientGuidPath,
      'get'
    );
    if (params) {
      rb.path('patientGuid', params.patientGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupAnswer>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCopyOfWorkupFormByPatientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCopyOfWorkupFormByPatientGuid(
    params: {
      patientGuid: string;
      sourceType: 'DONOR' | 'CORD' | 'BDP';
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupAnswer> {
    return this.getCopyOfWorkupFormByPatientGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>) => r.body as WorkupAnswer)
    );
  }

  /**
   * Path part for operation getCopyOfWorkupFormByPatientGuidAndFormCode
   */
  static readonly GetCopyOfWorkupFormByPatientGuidAndFormCodePath = '/workup-copy/answers/{patientGuid}/{formCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCopyOfWorkupFormByPatientGuidAndFormCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCopyOfWorkupFormByPatientGuidAndFormCode$Response(
    params: {
      patientGuid: string;
      formCode:
        | 'DNRIDM'
        | 'MATID2'
        | 'MSQMS2'
        | 'FHQFH2'
        | 'CBUID2'
        | 'EWU'
        | 'SECEWU'
        | 'EIDMs'
        | 'CD34ST'
        | 'CD3ST'
        | 'CFU'
        | 'CFUST'
        | 'NRBCST'
        | 'TNCST'
        | 'VIAB'
        | 'VIABST'
        | 'NATHCV'
        | 'NATHIV'
        | 'EOR'
        | 'PROCES';
      formVersion?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<WorkupAnswer>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CopyWorkupControllerService.GetCopyOfWorkupFormByPatientGuidAndFormCodePath,
      'get'
    );
    if (params) {
      rb.path('patientGuid', params.patientGuid, {});
      rb.path('formCode', params.formCode, {});
      rb.query('formVersion', params.formVersion, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WorkupAnswer>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCopyOfWorkupFormByPatientGuidAndFormCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCopyOfWorkupFormByPatientGuidAndFormCode(
    params: {
      patientGuid: string;
      formCode:
        | 'DNRIDM'
        | 'MATID2'
        | 'MSQMS2'
        | 'FHQFH2'
        | 'CBUID2'
        | 'EWU'
        | 'SECEWU'
        | 'EIDMs'
        | 'CD34ST'
        | 'CD3ST'
        | 'CFU'
        | 'CFUST'
        | 'NRBCST'
        | 'TNCST'
        | 'VIAB'
        | 'VIABST'
        | 'NATHCV'
        | 'NATHIV'
        | 'EOR'
        | 'PROCES';
      formVersion?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<WorkupAnswer> {
    return this.getCopyOfWorkupFormByPatientGuidAndFormCode$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>) => r.body as WorkupAnswer)
    );
  }
}
