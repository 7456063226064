/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BdpLookup } from '../models/bdp-lookup';
import { CordLookup } from '../models/cord-lookup';
import { DonorLookup } from '../models/donor-lookup';
import { DuplicatePatientLookupRequest } from '../models/duplicate-patient-lookup-request';
import { DuplicatesLookupResponse } from '../models/duplicates-lookup-response';
import { InventoryOrderLookupRequest } from '../models/inventory-order-lookup-request';
import { PageInventoryOrderLookup } from '../models/page-inventory-order-lookup';
import { PageRecipientLookup } from '../models/page-recipient-lookup';
import { RecipientLookupRequest } from '../models/recipient-lookup-request';

@Injectable({
  providedIn: 'root',
})
export class LookupControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation lookupRecipientBy
   */
  static readonly LookupRecipientByPath = '/lookup/recipient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupRecipientBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientBy$Response(params: {
    ignoreCoreFailed?: boolean;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: RecipientLookupRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageRecipientLookup>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.LookupRecipientByPath, 'post');
    if (params) {
      rb.query('ignoreCoreFailed', params.ignoreCoreFailed, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageRecipientLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupRecipientBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientBy(params: {
    ignoreCoreFailed?: boolean;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: RecipientLookupRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageRecipientLookup> {

    return this.lookupRecipientBy$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageRecipientLookup>) => r.body as PageRecipientLookup)
    );
  }

  /**
   * Path part for operation lookupInventoryOrdersBy
   */
  static readonly LookupInventoryOrdersByPath = '/lookup/inventory';

  /**
   * This method looks up inventory orders of specific type by bpGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupInventoryOrdersBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupInventoryOrdersBy$Response(params: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: InventoryOrderLookupRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageInventoryOrderLookup>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.LookupInventoryOrdersByPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageInventoryOrderLookup>;
      })
    );
  }

  /**
   * This method looks up inventory orders of specific type by bpGuid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupInventoryOrdersBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupInventoryOrdersBy(params: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: InventoryOrderLookupRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageInventoryOrderLookup> {

    return this.lookupInventoryOrdersBy$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageInventoryOrderLookup>) => r.body as PageInventoryOrderLookup)
    );
  }

  /**
   * Path part for operation duplicatePatientLookup
   */
  static readonly DuplicatePatientLookupPath = '/lookup/duplicate-patient';

  /**
   * This endpoint is used for Patient Import functionality by Pathfinder application. It's main purpose is to find existing patients that could probably be a duplicate of a patient staged for import.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicatePatientLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicatePatientLookup$Response(params: {
    context?: HttpContext
    body: DuplicatePatientLookupRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<DuplicatesLookupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.DuplicatePatientLookupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicatesLookupResponse>;
      })
    );
  }

  /**
   * This endpoint is used for Patient Import functionality by Pathfinder application. It's main purpose is to find existing patients that could probably be a duplicate of a patient staged for import.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `duplicatePatientLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicatePatientLookup(params: {
    context?: HttpContext
    body: DuplicatePatientLookupRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<DuplicatesLookupResponse> {

    return this.duplicatePatientLookup$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DuplicatesLookupResponse>) => r.body as DuplicatesLookupResponse)
    );
  }

  /**
   * Path part for operation lookupGuidByRid
   */
  static readonly LookupGuidByRidPath = '/lookup/guid/{rid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGuidByRid()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGuidByRid$Response(params: {
    rid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.LookupGuidByRidPath, 'get');
    if (params) {
      rb.path('rid', params.rid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupGuidByRid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGuidByRid(params: {
    rid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.lookupGuidByRid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation lookupDonorById
   */
  static readonly LookupDonorByIdPath = '/lookup/donor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupDonorById()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupDonorById$Response(params?: {
    donorId?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DonorLookup>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.LookupDonorByIdPath, 'get');
    if (params) {
      rb.query('donorId', params.donorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DonorLookup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupDonorById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupDonorById(params?: {
    donorId?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DonorLookup>> {

    return this.lookupDonorById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DonorLookup>>) => r.body as Array<DonorLookup>)
    );
  }

  /**
   * Path part for operation lookupCordById
   */
  static readonly LookupCordByIdPath = '/lookup/cord';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupCordById()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCordById$Response(params?: {
    cordId?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CordLookup>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.LookupCordByIdPath, 'get');
    if (params) {
      rb.query('cordId', params.cordId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CordLookup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupCordById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCordById(params?: {
    cordId?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CordLookup>> {

    return this.lookupCordById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CordLookup>>) => r.body as Array<CordLookup>)
    );
  }

  /**
   * Path part for operation lookupBdpById
   */
  static readonly LookupBdpByIdPath = '/lookup/bdp';

  /**
   * This method retrieves BDP's by Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupBdpById()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupBdpById$Response(params?: {
    bdpId?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BdpLookup>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupControllerService.LookupBdpByIdPath, 'get');
    if (params) {
      rb.query('bdpId', params.bdpId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BdpLookup>>;
      })
    );
  }

  /**
   * This method retrieves BDP's by Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `lookupBdpById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupBdpById(params?: {
    bdpId?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BdpLookup>> {

    return this.lookupBdpById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BdpLookup>>) => r.body as Array<BdpLookup>)
    );
  }

}
