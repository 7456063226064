/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {LabReport} from '../models/lab-report';

@Injectable({
  providedIn: 'root',
})
export class LabReportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getLabReports
   */
  static readonly GetLabReportsPath = '/lab-reports';

  /**
   * Fetch a list of lab reports for given subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabReports$Response(
    params: {
      subjectGuid: string;
      includeRelatedDonors?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<LabReport>>> {
    const rb = new RequestBuilder(this.rootUrl, LabReportControllerService.GetLabReportsPath, 'get');
    if (params) {
      rb.query('subjectGuid', params.subjectGuid, {});
      rb.query('includeRelatedDonors', params.includeRelatedDonors, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<LabReport>>;
        })
      );
  }

  /**
   * Fetch a list of lab reports for given subject
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabReports(
    params: {
      subjectGuid: string;
      includeRelatedDonors?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<LabReport>> {
    return this.getLabReports$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<LabReport>>) => r.body as Array<LabReport>)
    );
  }
}
