/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Donor } from '../models/donor';
import { DonorEligibility } from '../models/donor-eligibility';
import { EthnicityRaceInfo } from '../models/ethnicity-race-info';
import { Form } from '../models/form';
import { Kir } from '../models/kir';
import { SourceBase } from '../models/source-base';

@Injectable({
  providedIn: 'root',
})
export class DonorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDonorsByGuids
   */
  static readonly GetDonorsByGuidsPath = '/donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorsByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsByGuids$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Donor>>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorsByGuidsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Donor>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorsByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsByGuids(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Donor>> {

    return this.getDonorsByGuids$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Donor>>) => r.body as Array<Donor>)
    );
  }

  /**
   * Path part for operation getDonorEthnicityRaceInBulk
   */
  static readonly GetDonorEthnicityRaceInBulkPath = '/donors/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorEthnicityRaceInBulk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorEthnicityRaceInBulk$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorEthnicityRaceInBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: EthnicityRaceInfo;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorEthnicityRaceInBulk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorEthnicityRaceInBulk(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: EthnicityRaceInfo;
}> {

    return this.getDonorEthnicityRaceInBulk$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>) => r.body as {
[key: string]: EthnicityRaceInfo;
})
    );
  }

  /**
   * Path part for operation getDonorsBaseByGuids
   */
  static readonly GetDonorsBaseByGuidsPath = '/donors/base';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorsBaseByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsBaseByGuids$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<SourceBase>>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorsBaseByGuidsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SourceBase>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorsBaseByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsBaseByGuids(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<SourceBase>> {

    return this.getDonorsBaseByGuids$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<SourceBase>>) => r.body as Array<SourceBase>)
    );
  }

  /**
   * Path part for operation getDonorByGuid
   */
  static readonly GetDonorByGuidPath = '/donors/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorByGuid$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Donor>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorByGuidPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Donor>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorByGuid(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Donor> {

    return this.getDonorByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Donor>) => r.body as Donor)
    );
  }

  /**
   * Path part for operation getKir
   */
  static readonly GetKirPath = '/donors/{guid}/kir';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKir()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Kir>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetKirPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Kir>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getKir$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Kir> {

    return this.getKir$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Kir>) => r.body as Kir)
    );
  }

  /**
   * Path part for operation getDonorIdms
   */
  static readonly GetDonorIdmsPath = '/donors/{guid}/idms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorIdms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorIdms$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Form>>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorIdmsPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Form>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorIdms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorIdms(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Form>> {

    return this.getDonorIdms$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Form>>) => r.body as Array<Form>)
    );
  }

  /**
   * Path part for operation getDonorEthnicityRace
   */
  static readonly GetDonorEthnicityRacePath = '/donors/{guid}/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorEthnicityRace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEthnicityRace$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<EthnicityRaceInfo>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorEthnicityRacePath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EthnicityRaceInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorEthnicityRace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEthnicityRace(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<EthnicityRaceInfo> {

    return this.getDonorEthnicityRace$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<EthnicityRaceInfo>) => r.body as EthnicityRaceInfo)
    );
  }

  /**
   * Path part for operation getDonorEligibility
   */
  static readonly GetDonorEligibilityPath = '/donors/{guid}/eligibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorEligibility()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEligibility$Response(params: {
    guid: string;
    fulfillmentOrderGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<DonorEligibility>> {

    const rb = new RequestBuilder(this.rootUrl, DonorControllerService.GetDonorEligibilityPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.query('fulfillmentOrderGuid', params.fulfillmentOrderGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonorEligibility>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorEligibility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEligibility(params: {
    guid: string;
    fulfillmentOrderGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<DonorEligibility> {

    return this.getDonorEligibility$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DonorEligibility>) => r.body as DonorEligibility)
    );
  }

}
